import classes from "./Profile.module.css";
import ProfileContent from "./ProfileContent";
import CardHeader from "../UI/CardHeader";
import React from "react";
import { profileData } from "../../data/data";
const Profile = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className={classes.profile}>
      <CardHeader title="Profile" description={profileData.headerDescription} />
      <ProfileContent />
    </div>
  );
});
export default Profile;
