import classes from "./ProfileContentAbout.module.css";
const ProfileContentAbout = (props) => {
  return (
    <div className={classes["profile-content__about"]}>
      <h1>About me</h1>
      <p>{props.about}</p>
    </div>
  );
};
export default ProfileContentAbout;
