import { useState } from "react";
import classes from "./Item.module.css";
import { GoChevronDown, GoChevronUp } from "react-icons/go";

const getYearsFromdate = (time1, time2) => {
  const timeDiff = time2 - time1;
  var timeDiffDate = new Date(timeDiff); // miliseconds from epoch
  let years = Math.abs(timeDiffDate.getUTCFullYear() - 1970);
  if (years === 0 || years < 0) {
    years = "";
  }
  if (years === 1) {
    years = "1 year";
  }
  if (years > 1) {
    years = String(years) + " years";
  }
  return years;
};
const getMonthsFromdate = (time1, time2) => {
  const timeDiff = time2 - time1;
  const timeDiffDate = new Date(timeDiff); // miliseconds from epoch
  let months = Math.abs(timeDiffDate.getUTCMonth());
  if (months === 0 || months < 0) {
    months = "";
  }
  if (months === 1) {
    months = "1 month";
  }
  if (months > 1) {
    months = String(months) + " months";
  }
  return months;
};

const Item = (props) => {
  let years = "";
  let months = "";
  const dates = props.duration.split("-");
  const [expandCard, setExpandCard] = useState(false);
  const expandButtonHandler = () => {
    setExpandCard((state) => !state);
  };

  if (dates[1].trim() === "current") {
    years = getYearsFromdate(new Date(dates[0]), Date.now());
    months = getMonthsFromdate(new Date(dates[0]), Date.now());
  } else {
    years = getYearsFromdate(new Date(dates[0]), new Date(dates[1]));
    months = getMonthsFromdate(new Date(dates[0]), new Date(dates[1]));
  }
  return (
    <>
      <div
        className={`${classes["item-card"]} ${
          !props.lastItem ? classes["bottom-border"] : null
        } `}
      >
        <div className={classes["heading-card"]}>
          <h1
            className={`${
              props.theme === "2"
                ? classes["background-2-text"]
                : classes["background-1-text"]
            } ${classes["item-card__company_name"]}`}
          >
            {props.name}
          </h1>
          <p
            className={`${
              props.theme === "2"
                ? classes["background-2-text"]
                : classes["background-1-text"]
            } ${classes["item-card__duration"]}`}
          >
            {years} {months}
          </p>
        </div>
        <div className={classes["description-card"]}>
          <h4
            className={`${
              props.theme === "2"
                ? classes["background-2-text"]
                : classes["background-1-text"]
            } ${classes["item-card__duration_time"]}`}
          >
            {props.duration}
          </h4>
          <h3
            className={`${
              props.theme === "2"
                ? classes["background-2-text"]
                : classes["background-1-text"]
            } ${classes["item-card__role"]}`}
          >
            {props.role}
          </h3>
          <h4
            className={`${
              props.theme === "2"
                ? classes["background-2-text"]
                : classes["background-1-text"]
            } ${classes["item-card__location"]}`}
          >
            {props.location}
          </h4>
          {props.description && (
            <button
              onClick={expandButtonHandler}
              className={classes["expand-button"]}
            >
              {expandCard ? (
                <GoChevronUp className={classes.arrow} />
              ) : (
                <GoChevronDown className={classes.arrow} />
              )}
            </button>
          )}
          {props.description && (
            <div
              className={`${classes["expandable-card"]} ${
                expandCard
                  ? classes["expand-card"]
                  : classes["background-1-text"]
              } `}
            >
              <p
                className={`${
                  props.theme === "2"
                    ? classes["background-2-text"]
                    : classes["background-1-text"]
                } `}
              >
                {props.description}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Item;
