import classes from "./EducationList.module.css";
import Item from "../UI/Item";

const EducationList = (props) => {
  return (
    <div className={classes["education-list"]}>
      {props.educationList.map((data, index) => {
        if (index === props.educationList.length - 1) {
          return (
            <Item
              key={Math.random()}
              name={data.school}
              location={data.location}
              role={data.degree}
              duration={data.duration}
              lastItem={true}
              description={data.description}
            />
          );
        }
        return (
          <Item
            key={Math.random()}
            name={data.school}
            location={data.location}
            role={data.degree}
            duration={data.duration}
            description={data.description}
          />
        );
      })}
    </div>
  );
};
export default EducationList;
