import classes from "./ProfileContentImage.module.css";
import myImage from "../../images/my-photo.jfif";
const ProfileContentImage = () => {
  return (
    <div className={classes["profile-content__image_container"]}>
      <img src={myImage} alt="my profile" />
    </div>
  );
};
export default ProfileContentImage;
