import classes from "./Contact.module.css";
import CardHeader from "../UI/CardHeader";
import ContactForm from "./ContactForm";
import React from "react";
import { contactData } from "../../data/data";
const Contact = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className={classes.contact}>
      <CardHeader
        title="Contact Me"
        description={contactData.headerDescription}
        theme="2"
      />
      <ContactForm />
    </div>
  );
});
export default Contact;
