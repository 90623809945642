import "./App.css";
import Header from "./compenents/Header/Header";
import Profile from "./compenents/Profile/Profile";
import Experiences from "./compenents/Experiences/Experiences";
import Education from "./compenents/Education/Education";
import Skills from "./compenents/Skills/Skills";
import Certification from "./compenents/Certifications/Certification";
import Contact from "./compenents/Contact/Contact";
import { useEffect, useRef, useState } from "react";
import Jumbotron from "./compenents/Jumbotron/Jumbotron";
import Background from "./compenents/UI/Background";
import Projects from "./compenents/Projects/Projects";

function App() {
  const experienceRef = useRef();
  const profileRef = useRef();
  const skillsRef = useRef();
  const educationRef = useRef();
  const certificationRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();
  const [activeHeader, setActiveHeader] = useState("Profile");
  const handleScroll = () => {
    if (
      window.pageYOffset + 10 > contactRef.current.offsetTop ||
      window.innerHeight + window.pageYOffset + 1 >
        contactRef.current.offsetTop + contactRef.current.offsetHeight
    ) {
      setActiveHeader("Contact");
    } else if (window.pageYOffset + 10 > certificationRef.current.offsetTop) {
      setActiveHeader("Certifications");
    } else if (window.pageYOffset + 10 > skillsRef.current.offsetTop) {
      setActiveHeader("Skills");
    } else if (window.pageYOffset + 10 > projectsRef.current.offsetTop) {
      setActiveHeader("Projects");
    } else if (window.pageYOffset + 10 > educationRef.current.offsetTop) {
      setActiveHeader("Education");
    } else if (window.pageYOffset + 10 > experienceRef.current.offsetTop) {
      setActiveHeader("Experience");
    } else {
      setActiveHeader("Profile");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  useEffect(() => {
    const randomInt = randomIntFromInterval(1, 3);
    var randomColorA = `${
      randomInt !== 1
        ? randomIntFromInterval(128, 190)
        : randomIntFromInterval(20, 100)
    }`;
    var randomColorB = `${
      randomInt !== 2
        ? randomIntFromInterval(128, 190)
        : randomIntFromInterval(20, 100)
    }`;
    var randomColorC = `${
      randomInt !== 3
        ? randomIntFromInterval(128, 190)
        : randomIntFromInterval(20, 100)
    }`;

    const randomColor = `rgb(${randomColorA},${randomColorB},${randomColorC})`;
    const randomColorFaded = `rgba(${randomColorA},${randomColorB},${randomColorC},0.7)`;
    const closeRandomColor = `rgb(${parseInt(randomColorA) - 20},${
      parseInt(randomColorB) - 20
    },${parseInt(randomColorC) - 20})`;
    const closeRandomColorHighlight = `rgb(${parseInt(randomColorA) + 20},${
      parseInt(randomColorB) + 20
    },${parseInt(randomColorC) + 20})`;
    const closeRandomColor2 = `rgb(${parseInt(randomColorA) - 15},${
      parseInt(randomColorB) + 15
    },${parseInt(randomColorC) + 15})`;
    const closeRandomColor3 = `rgb(${
      parseInt(randomColorA) < 101
        ? parseInt(randomColorA) + 10
        : parseInt(randomColorA) - 10
    },${
      parseInt(randomColorB) < 101
        ? parseInt(randomColorB) + 10
        : parseInt(randomColorB) - 10
    },${
      parseInt(randomColorC) < 101
        ? parseInt(randomColorC) + 10
        : parseInt(randomColorC) - 10
    })`;
    console.log(randomColor);
    /*
  --background-color-2: #006080;
  --background-color-2-faded: #006080b9;
    --contact-color: #103257;
  --secondary-heading-color: #205d86;
  --card-header-font-color-1: #006080;
  --header-bar-color: #16405b;
  --header-options-color: #16405b;
  --header-options-highlight-color: #205d86;
    */
    document.documentElement.style.setProperty(
      "--background-color-2",
      randomColor
    );
    document.documentElement.style.setProperty(
      "--background-color-2-faded",
      randomColorFaded
    );
    document.documentElement.style.setProperty(
      "--contact-color",
      closeRandomColor
    );
    document.documentElement.style.setProperty(
      "--card-header-font-color-1",
      closeRandomColorHighlight
    );
    document.documentElement.style.setProperty(
      "--secondary-heading-color",
      closeRandomColor2
    );
    document.documentElement.style.setProperty(
      "--header-bar-color",
      closeRandomColorHighlight
    );
    document.documentElement.style.setProperty(
      "--header-options-color",
      closeRandomColor3
    );
    document.documentElement.style.setProperty(
      "--header-options-highlight-color",
      closeRandomColorHighlight
    );

    return () => {};
  }, []);
  const executeScroll = (scrollTo) => {
    switch (scrollTo) {
      case "Profile":
        profileRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Experience":
        experienceRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Education":
        educationRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Projects":
        projectsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Skills":
        skillsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Certifications":
        certificationRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Contact":
        contactRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        console.log("Error in Scrolling");
        console.log(scrollTo);
    }
  };

  return (
    <>
      <Background />
      <Jumbotron executeScroll={executeScroll} />
      <Header activeHeader={activeHeader} executeScroll={executeScroll} />
      <main>
        <section>
          <Profile ref={profileRef} />
          <Experiences ref={experienceRef} />
          <Education ref={educationRef} />
          <Projects ref={projectsRef} />
          <Skills ref={skillsRef} />
          <Certification ref={certificationRef} />
        </section>
      </main>
      <footer>
        <Contact ref={contactRef} />
      </footer>
    </>
  );
}

export default App;
