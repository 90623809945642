import classes from "./SkillList.module.css";
import SkillItem from "./SkillItem";
const SkillList = (props) => {
  return (
    <div className={classes["skill-list"]}>
      {props.skillsList.map((data) => (
        <SkillItem
          key={Math.random()}
          name={data.skillName}
          efficiency={data.efficiency}
        />
      ))}
    </div>
  );
};
export default SkillList;
