import resumeAppImage from "./projectImages/backgroundImage.jfif";
import educativeCourseImage from "./projectImages/awsSys.png";
import rundwnImage from "./projectImages/rundwn.png";

const getAge = (birthDate) => {
  const birthDateObject = new Date(birthDate);
  const timeDiff = Date.now() - birthDateObject;
  const timeDiffDate = new Date(timeDiff);
  const years = Math.abs(timeDiffDate.getUTCFullYear() - 1970);
  return years;
};

export const profileData = {
  headerDescription: "Dedicated Software Engineer and Researcher with a strong passion for cloud technology and web development.",
  aboutMe:
    "My career goal is to secure a challenging role within an organization that focuses on technical excellence, innovation, and personal growth. I aim to leverage my skills to become a dependable member of my team.",
  firstName: "Naman",
  secondName: "Yash",
  age: getAge("20 Apr 1999"),
  location: "Melbourne, Australia",
  linkedInProfileLink: "https://www.linkedin.com/in/naman-yash/",
};
export const experienceData = {
  headerDescription: "My professional life has been a great teacher.",
  careerList: [
    {
      companyName: "Deakin University",
      locationText: "Melbourne, Australia",
      role: "Casual Sessional Academic",
      duration: "Mar 2024 - May 2024",
      description: "Taught workshops and graded students for two units: Cloud Computing (AWS), Mobile App development (Android - JAVA).",
    },
    {
      companyName: "Deakin University",
      locationText: "Melbourne, Australia",
      role: "Research Assistant",
      duration: "Aug 2023 - Feb 2024",
      description: "Experimented on Quantum Federated Learning (QFL) using IBM’s Qiskit library and Python. Implemented the solution and ran final tests on AWS.",
    },
    {
      companyName: "JP Morgan Chase",
      locationText: "Bengaluru, India",
      role: "Software Engineer",
      duration: "Jul 2020 - May 2022",
      description: "Worked on AWS Infrastructure as a code, using the AWS CloudFormation and Terraform. Achieved AWS Certfied Solutions Architect - Associate, AWS Developer - Associate, AWS Certified SysOps Administrator - Associate and Terraform Developer - Associate.",
    },
    {
      companyName: "JP Morgan Chase",
      locationText: "Bengaluru, India",
      role: "Software Engineering Intern",
      duration: "Jan 2020 - Jun 2020",
      description: "Worked on AWS Infrastructure as a code, using the AWS CloudFormation and Terraform. Built a primer application using ReactJS and NodeJS that utilizes AWS components like S3, SQS, RDS, Lambda, and more.",
    },
    {
      companyName: "JP Morgan Chase",
      locationText: "Hyderabad, India",
      role: "Software Engineering Summer Intern",
      duration: "May 2019 - Jul 2019",
      description: "Worked on a SpringBoot application to build a feature that allowed the team to view MS Excel files in the browser and scan for errors.",
    },
  ],
};

export const educationData = {
  headerDescription: "For what are we without education.",
  educationList: [
    {
      school: "Deakin University",
      location: "Burwood",
      degree: "Master of IT",
      duration: "Jul 2022 - Jul 2024",
      description: "Currently pursuing a Master of IT with a focus on cloud technologies and software development practices.",
    },
    {
      school: "Thapar Institute of Engineering and Technology",
      location: "Patiala, India",
      degree: "Bachelor of Computer Engineering",
      duration: "Jul 2016 - Jun 2020",
      description:
        "My bachelor's was a great experience. The curriculum helped me learn about fundamentals of computer engineering. I studied data structures, software engineering, and multiple other core engineering subjects. I did personal projects and courses that ultimately helped understanding computer programming and areas of web development.",
    },
  ],
};

export const projectsData = {
  headerDescription: "Some of my projects that I am proud of",
  projectList: [
    {
      title: "AWS SysOps Administrator – Associate Course",
      link: "https://www.educative.io/collectioneditor/6586453712175104/5315385306120192",
            backgroundImagePath: educativeCourseImage,

      uses: "Built a course for Educative.io on the topic. The course utilizes Linux machines to run AWS CLI commands to demonstrate AWS features to students.",
    },
    {
      title: "Enhance Live Streaming Run of show",
      link: "https://Rundwn.live",
            backgroundImagePath: rundwnImage,

      uses: "Led the development of the backend for rundwn.live, a startup offering live production teacking service as a Platform as a Service (PaaS), facilitating pre-production of live show rundowns and real-time updates for the production team. Implemented AWS services and REST APIs to establish a scalable infrastructure.",
    },
    {
      title: "Resume App",
      link: "http://namanyash.com",
      backgroundImagePath: resumeAppImage,
      uses: "Developed a personal resume website as a showcase of my ReactJS and AWS skills. The integrates with AWS API Gateway and AWS Lambda to handle backend operations, enhancing scalability. The website is hosted on AWS S3 and CloudFront."
    },
  ],
};


export const skillsData = {
  headerDescription: "It is incredible when skills and passion come together.",
  skillsList: [
    {
      skillName: "AWS",
      efficiency: 5,
    },
    {
      skillName: "CSS3",
      efficiency: 5,
    },
    {
      skillName: "HTML5",
      efficiency: 5,
    },
    {
      skillName: "Bootstrap Framework",
      efficiency: 5,
    },
    {
      skillName: "JSON",
      efficiency: 5,
    },
    {
      skillName: "Terraform",
      efficiency: 5,
    },
    {
      skillName: "AWS CloudFormation",
      efficiency: 5,
    },
    {
      skillName: "ReactJS",
      efficiency: 4,
    },
    {
      skillName: "JAVA",
      efficiency: 4,
    },
    {
      skillName: "SpringBoot",
      efficiency: 4,
    },
    {
      skillName: "Python",
      efficiency: 3,
    },
    {
      skillName: "SQL",
      efficiency: 3,
    },

    {
      skillName: "NodeJS",
      efficiency: 3,
    },
    {
      skillName: "GoLang",
      efficiency: 2,
    },
  ],
};
export const certificationData = {
  headerDescription: "My professional achievements.",
  certificationList: [
    {
      certification: "AWS Certified SysOps Administrator - Associate",
      issuingService: "Amazon Web Services (AWS)",
      issued: "Jul 2021",
      expires: "Jul 2024",
      link: "https://www.credly.com/badges/f35c9316-beff-4610-9435-833e7e1bfb3e/",
    },
  ],
};
export const contactData = {
  headerDescription:
    "I might not know the answers to your questions but I know I can find them. :)",
};
