import classes from "./Certification.module.css";
import CardHeader from "../UI/CardHeader";
import CertificationList from "./CertificationList";
import React from "react";
import { certificationData } from "../../data/data";
const Certification = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className={classes.certification}>
      <CardHeader
        title="Certifications"
        description={certificationData.headerDescription}
        theme="2"
      />
      <CertificationList
        certificationList={certificationData.certificationList}
      />
    </div>
  );
});
export default Certification;
