import classes from "./Background.module.css";
const Background = (props) => {
  //   const height = window.innerHeight + "px";

  return (
    <div
      //   style={{ height: height }}
      className={classes["page-background"]}
    ></div>
  );
};
export default Background;
