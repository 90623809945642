import React, { useEffect, useState } from "react";
import classes from "./Jumbotron.module.css";
import { profileData } from "../../data/data";
import JumbotronButton from "./JumbotronButton";

const Jumbotron = React.forwardRef((props, ref) => {
  const [path] = useState(window.innerHeight);
  const [height, setHeight] = useState(path);
  useEffect(() => {
    window.addEventListener("resize", function (event) {
      setHeight(window.innerHeight);
    });
  }, []);
  return (
    <div style={{ height: height }} ref={ref} className={classes.jumbotron}>
      <div className={classes["jumbotron-content"]}>
        <h1>Hi I'm {profileData.firstName}, I am a web developer.</h1>
        <h2>
          Checkout{" "}
          <span
            onClick={props.executeScroll.bind(null, "Profile")}
            className={classes["jumbotron-content-links"]}
          >
            my profile
          </span>{" "}
          or{" "}
          <span
            onClick={props.executeScroll.bind(null, "Contact")}
            className={classes["jumbotron-content-links"]}
          >
            say hi
          </span>
          .
        </h2>
      </div>
      <JumbotronButton executeScroll={props.executeScroll} />
    </div>
  );
});
export default Jumbotron;
