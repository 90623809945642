import classes from "./ProjectCard.module.css";
import React from "react";
import ProjectCardImage from "./ProjectCardImage";

const ProjectCard = (props) => {
  return (
    <div className={classes["project-card"]}>
      <ProjectCardImage
        link={props.link}
        tags={props.tags}
        title={props.title}
        backgroundImagePath={props.backgroundImagePath}
      />
      {/* <div
        className={`${classes["project-text-card"]} ${
          !props.lastItem ? classes["bottom-border"] : null
        }`}
      >
        <h1>My Interactive Resume</h1>
        <p>
          Project description is a sm sda dad dsa Project description is a sm
          sda dad dsa Project description is a sm sda dad dsa Project
          description is a sm sda dad dsa Project description is a sm sda dad
          dsa Project description is a sm sda dad dsa Project description is a
          sm sda dad dsa
        </p>
      </div> */}
    </div>
  );
};
export default ProjectCard;
