import classes from "./CertificationCard.module.css";
const CertificationCard = (props) => {
  return (
    <>
      <div className={`${classes["certification-card"]}`}>
        <a href={props.link} rel="noreferrer" target="_blank">
          <h1>{props.certification}</h1>
          <h2>{props.issuingService}</h2>
          <p>
            {props.issued} {props.expires}
          </p>
        </a>
      </div>
      <div className={!props.lastItem ? classes["bottom-border"] : null}></div>
    </>
  );
};
export default CertificationCard;
