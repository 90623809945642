import classes from "./CardHeader.module.css";
const CardHeader = (props) => {
  return (
    <div
      className={`${classes["profile-header"]} ${
        props.theme === "2" ? classes["profile-header-theme-2"] : null
      }`}
    >
      <h1>{props.title}</h1>
      <p>{props.description}</p>
    </div>
  );
};
export default CardHeader;
