import { useReducer, useState } from "react";
import { connectToSNS } from "./ConnectToSNS";
import classes from "./ContactForm.module.css";
const ContactForm = () => {
  const DEFAULT_FORM_INPUT_STATE = {
    input: "",
    valid: false,
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const inputNotEmptyReducer = (state, action) => {
    if (action.type === "NEW_INPUT") {
      if (action.value.trim() !== "") {
        return {
          input: action.value,
          valid: true,
        };
      } else {
        return {
          input: action.value,
          valid: false,
        };
      }
    }
    return DEFAULT_FORM_INPUT_STATE;
  };
  const emailValidationReducer = (state, action) => {
    if (action.type === "NEW_INPUT") {
      if (validateEmail(action.value)) {
        return {
          input: action.value,
          valid: true,
        };
      } else {
        return {
          input: action.value,
          valid: false,
        };
      }
    }
    return DEFAULT_FORM_INPUT_STATE;
  };

  const [messageSendingStatus, setMessageSendingStatus] = useState("");

  const [nameState, dispatchNameAction] = useReducer(
    inputNotEmptyReducer,
    DEFAULT_FORM_INPUT_STATE
  );
  const [messageState, dispatchMessageAction] = useReducer(
    inputNotEmptyReducer,
    DEFAULT_FORM_INPUT_STATE
  );
  const [emailState, dispatchEmailAction] = useReducer(
    emailValidationReducer,
    DEFAULT_FORM_INPUT_STATE
  );
  const [showError, setShowError] = useState(false);

  const nameChangeHandler = (event) => {
    setMessageSendingStatus("");
    dispatchNameAction({ type: "NEW_INPUT", value: event.target.value });
  };
  const messageChangeHandler = (event) => {
    setMessageSendingStatus("");
    dispatchMessageAction({ type: "NEW_INPUT", value: event.target.value });
  };
  const emailChangeHandler = (event) => {
    setMessageSendingStatus("");
    dispatchEmailAction({ type: "NEW_INPUT", value: event.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!emailState.valid || !nameState.valid || !messageState.valid) {
      setShowError(true);
      return;
    }
    setMessageSendingStatus(
      <p className={classes["form-message"]}>Sending ...</p>
    );
    console.log("here1");
    const SNSApiResult = connectToSNS({
      name: nameState.input,
      email: emailState.input,
      message: messageState.input,
    });
    if (SNSApiResult) {
      setMessageSendingStatus(
        <p className={classes["form-success-message"]}>
          Thank You for reaching out. I have recieved your message. :)
        </p>
      );
    } else {
      setMessageSendingStatus(
        <p className={classes["form-error-message"]}>
          Thank You for trying to reach out. There was an issue connecting to
          the backend. I'm on it.
        </p>
      );
    }
    dispatchNameAction({ type: "CLEAR_INPUT", value: event.target.value });
    dispatchMessageAction({ type: "CLEAR_INPUT", value: event.target.value });
    dispatchEmailAction({ type: "CLEAR_INPUT", value: event.target.value });
  };
  if (showError === true) {
    if (emailState.valid && nameState.valid && messageState.valid) {
      setShowError(false);
    }
  }
  return (
    <div className={classes["contact-form-box"]}>
      <form onSubmit={submitHandler} className={classes["contact-form"]}>
        <div className={classes.control}>
          <label>Name</label>
          <input
            type="text"
            value={nameState.input}
            onChange={nameChangeHandler}
          />
          {showError && !nameState.valid && (
            <p className={classes["form-error-message"]}>
              Name must not be empty.
            </p>
          )}
        </div>
        <div className={classes.control}>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            value={emailState.input}
            onChange={emailChangeHandler}
          />
          {showError && !emailState.valid && (
            <p className={classes["form-error-message"]}>
              Invalid Email Address
            </p>
          )}
        </div>
        <div className={classes.control}>
          <label htmlFor="opening-text">Message</label>
          <textarea
            rows="5"
            id="opening-text"
            value={messageState.input}
            onChange={messageChangeHandler}
          ></textarea>
          {showError && !messageState.valid && (
            <p className={classes["form-error-message"]}>
              Message must not be empty.
            </p>
          )}
        </div>
        {messageSendingStatus}
        <button type="submit">Send</button>
      </form>
    </div>
  );
};
export default ContactForm;
