import classes from "./SkillItem.module.css";
import { FaStar } from "react-icons/fa";

const SkillItem = (props) => {
  return (
    <div className={classes["skill-item"]}>
      <h1>{props.name}</h1>
      <div className={classes["skill-item__star-list"]}>
        {[...Array(props.efficiency)].map(() => (
          <FaStar
            key={Math.random()}
            className={`${classes["checked-star"]} ${classes["star"]}`}
          />
        ))}
        {[...Array(5 - props.efficiency)].map(() => (
          <FaStar
            key={Math.random()}
            className={`${classes["unchecked-star"]} ${classes["star"]}`}
          />
        ))}
      </div>
    </div>
  );
};
export default SkillItem;
