import classes from "./HeaderButton.module.css";
const HeaderButton = (props) => {
  return (
    <button className={classes["toggle-button"]} onClick={props.onClick}>
      <span className={classes["toggle-button__bar"]}></span>
      <span className={classes["toggle-button__bar"]}></span>
      <span className={classes["toggle-button__bar"]}></span>
    </button>
  );
};
export default HeaderButton;
