export const connectToSNS = (data) => {
  return fetch(
    "https://mrxgkw76jc.execute-api.ap-southeast-1.amazonaws.com/DEV/resumeAppNotification",
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": "Ca4qKTz2rr1qhNRz4GoDW2juN4RFakIRGgQAiSh0",
      },
    }
  )
    .then(function (response) {
      // console.log(response);
      // console.log(response.json());
      return response.json();
    })
    .then(function (responseData) {
      if (responseData === "Success" || responseData === "Failiure") {
        return true;
      }
      return false;
    });
};
