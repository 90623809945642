import classes from "./ProjectCardImage.module.css";
import React from "react";

const ProjectCardImage = (props) => {
  return (
    // <div className={classes["project-image-place-holder"]}>
    //   <a href="namanyash.com">
    //     <div className={classes["project-image-card"]}>
    //       <img src={projectImage} alt="project tile" />
    //       <div className={classes["project-image-content"]}>
    //         <h1>My Interactive Resume</h1>
    //         <p>Tags: react sada sda sad sasd</p>
    //       </div>
    //     </div>
    //   </a>
    // </div>
    <div className={classes["project-image-card"]}>
      <figure className={classes["project-image-figure"]}>
        <img src={props.backgroundImagePath} alt="project tile" />
        <a href={props.link}>
          <div className={classes["project-image-content"]}>
            <h1>{props.title}</h1>
            <h2>{props.tags}</h2>
          </div>
        </a>
      </figure>
    </div>
  );
};
export default ProjectCardImage;
