import React from "react";
import { GoChevronDown } from "react-icons/go";
import classes from "./JumbotronButton.module.css";

const JumbotronButton = (props) => {
  return (
    <button onClick={props.executeScroll.bind(null, "Profile")}>
      <span className={classes["jumbotron-button-content"]}>
        <GoChevronDown size={"2rem"} />
      </span>
    </button>
  );
};
export default JumbotronButton;
