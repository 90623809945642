import CertificationCard from "./CertificationCard";
import classes from "./CertificationList.module.css";

const CertificationList = (props) => {
  return (
    <div className={classes["certification-list"]}>
      {props.certificationList.map((data, index) => {
        if (index === props.certificationList.length - 1) {
          return (
            <CertificationCard
              key={Math.random()}
              certification={data.certification}
              link={data.link}
              issuingService={data.issuingService}
              issued={data.issued}
              expires={data.expires}
              lastItem={true}
            />
          );
        }
        return (
          <CertificationCard
            key={Math.random()}
            certification={data.certification}
            link={data.link}
            issuingService={data.issuingService}
            issued={data.issued}
            expires={data.expires}
          />
        );
      })}
    </div>
  );
};
export default CertificationList;
