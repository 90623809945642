import classes from "./Skills.module.css";
import CardHeader from "../UI/CardHeader";
import SkillList from "./SkillList";
import React from "react";
import { skillsData } from "../../data/data";
const Skills = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className={classes.skills}>
      <CardHeader
        title="Skills"
        description={skillsData.headerDescription}
        theme="1"
      />
      <SkillList skillsList={skillsData.skillsList} />
    </div>
  );
});
export default Skills;
