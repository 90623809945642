import classes from "./CareerList.module.css";
import Item from "../UI/Item";

const CareerList = (props) => {
  return (
    <div className={classes["career-list"]}>
      {props.careerList.map((data, index) => {
        if (index === props.careerList.length - 1) {
          return (
            <Item
              key={Math.random()}
              name={data.companyName}
              location={data.locationText}
              role={data.role}
              duration={data.duration}
              lastItem={true}
              description={data.description}
              theme="2"
            />
          );
        }
        return (
          <Item
            key={Math.random()}
            name={data.companyName}
            location={data.locationText}
            role={data.role}
            duration={data.duration}
            description={data.description}
            theme="2"
          />
        );
      })}
    </div>
  );
};
export default CareerList;
