import classes from "./Projects.module.css";
import CardHeader from "../UI/CardHeader";
import React from "react";
import ProjectList from "./ProjectList";
import { projectsData } from "../../data/data";
const Projects = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className={classes.projects}>
      <CardHeader
        title="Projects"
        description={projectsData.headerDescription}
        theme="2"
      />
      <ProjectList projectList={projectsData.projectList} />
    </div>
  );
});
export default Projects;
