import { useEffect, useState } from "react";
import classes from "./ScrollCard.module.css";

const ScrollCard = (props) => {
  const [scrollCardPosition, setScrollCardPosition] = useState("absolute");
  const handleScroll = () => {
    if (window.pageYOffset > 0.95 * window.innerHeight) {
      setScrollCardPosition("fixed");
    } else {
      setScrollCardPosition("absolute");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div
      className={`${classes["scroll-card"]} ${
        props.showBar ? null : classes.hide
      }  ${scrollCardPosition === "fixed" ? classes.fixed : null}`}
    >
      <div
        className={`${classes["scroll-card-box"]} ${
          props.activeHeader === "Profile" ? classes.active : null
        }`}
        onClick={props.executeScroll.bind(null, "Profile")}
      >
        <h1>Profile</h1>
      </div>
      <div
        className={`${classes["scroll-card-box"]} ${
          props.activeHeader === "Experience" ? classes.active : null
        }`}
        onClick={props.executeScroll.bind(null, "Experience")}
      >
        <h1>Experience</h1>
      </div>
      <div
        className={`${classes["scroll-card-box"]} ${
          props.activeHeader === "Education" ? classes.active : null
        }`}
        onClick={props.executeScroll.bind(null, "Education")}
      >
        <h1>Education</h1>
      </div>
      <div
        className={`${classes["scroll-card-box"]} ${
          props.activeHeader === "Projects" ? classes.active : null
        }`}
        onClick={props.executeScroll.bind(null, "Projects")}
      >
        <h1>Projects</h1>
      </div>
      <div
        className={`${classes["scroll-card-box"]} ${
          props.activeHeader === "Skills" ? classes.active : null
        }`}
        onClick={props.executeScroll.bind(null, "Skills")}
      >
        <h1>Skills</h1>
      </div>
      <div
        className={`${classes["scroll-card-box"]} ${
          props.activeHeader === "Certifications" ? classes.active : null
        }`}
        onClick={props.executeScroll.bind(null, "Certifications")}
      >
        <h1>Certifications</h1>
      </div>
      <div
        className={`${classes["scroll-card-box"]} ${
          props.activeHeader === "Contact" ? classes.active : null
        }`}
        onClick={props.executeScroll.bind(null, "Contact")}
      >
        <h1>Contact</h1>
      </div>
    </div>
  );
};
export default ScrollCard;
