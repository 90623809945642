import classes from "./ProfileContent.module.css";
import ProfileContentAbout from "./ProfileContentAbout";
import ProfileContentDetails from "./ProfileContentDetails";
import ProfileContentImage from "./ProfileContentImage";
import { profileData } from "../../data/data";
const ProfileContent = () => {
  return (
    <div className={classes["profile-content"]}>
      <ProfileContentAbout about={profileData.aboutMe} />
      <ProfileContentImage />
      <ProfileContentDetails
        firstName={profileData.firstName}
        secondName={profileData.secondName}
        name={profileData.firstName}
        age={profileData.age}
        location={profileData.location}
        linkedInLink={profileData.linkedInProfileLink}
      />
    </div>
  );
};
export default ProfileContent;
