import { useEffect, useState } from "react";
import classes from "./Header.module.css";
import HeaderButton from "./HeaderButton";
import ScrollCard from "./ScrollCard";
const Header = (props) => {
  const [showBar, setShowBar] = useState(false);
  const [headerPosition, setHeaderPosition] = useState("absolute");
  const handleScroll = () => {
    if (window.pageYOffset > window.innerHeight) {
      setHeaderPosition("fixed");
    } else {
      setHeaderPosition("absolute");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const buttonClickHandler = () => {
    setShowBar((state) => !state);
  };
  const scrollHandler = (scrollTo, event) => {
    setShowBar((state) => !state);
    props.executeScroll(scrollTo);
  };
  return (
    <>
      <header
        className={`${classes.header} ${
          headerPosition === "fixed" ? classes.fixed : null
        }`}
      >
        <HeaderButton
          className={classes["header-button"]}
          onClick={buttonClickHandler}
        />
        <ScrollCard
          activeHeader={props.activeHeader}
          showBar={showBar}
          executeScroll={scrollHandler}
        />
      </header>
    </>
  );
};
export default Header;
