import classes from "./Experiences.module.css";
import CardHeader from "../UI/CardHeader";
import CareerList from "./CareerList";
import React from "react";
import { experienceData } from "../../data/data";
const Experiences = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className={classes.experiences}>
      <CardHeader
        title="Experience"
        description={experienceData.headerDescription}
        theme="2"
      />
      <CareerList careerList={experienceData.careerList}/>
    </div>
  );
});
export default Experiences;
