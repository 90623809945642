import classes from "./Education.module.css";
import CardHeader from "../UI/CardHeader";
import EducationList from "./EducationList";
import React from "react";
import { educationData } from "../../data/data";
const Education = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className={classes.education}>
      <CardHeader
        title="Education"
        description={educationData.headerDescription}
      />
      <EducationList educationList={educationData.educationList} />
    </div>
  );
});
export default Education;
