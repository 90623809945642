import classes from "./ProfileContentDetails.module.css";
import linkedInImage from "../../images/linkedIn.png";
const ProfileContentDetails = (props) => {
  return (
    <div className={classes["profile-content__details"]}>
      <h1>Details </h1>
      <div className={classes["profile-content__details_info"]}>
        <h2>Name</h2>
        <h3>
          {props.firstName} {props.secondName}
        </h3>
        <h2>Age</h2>
        <h3>{props.age}</h3>
        <h2>Location</h2>
        <h3>{props.location}</h3>
        <div className={classes["profile-content__details_linkedIn"]}>
          <a href={props.linkedInLink} rel="noreferrer" target="_blank">
            <img src={linkedInImage} alt="linkedIn" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default ProfileContentDetails;
