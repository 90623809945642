import classes from "./ProjectList.module.css";
import React from "react";
import ProjectCard from "./ProjectCard";
const ProjectList = (props) => {
  return (
    <div className={classes["project-list"]}>
      {props.projectList.map((data, index) => (
        <ProjectCard
          key={Math.random()}
          link={data.link}
          tags={data.uses}
          title={data.title}
          backgroundImagePath={data.backgroundImagePath}
        />
      ))}
    </div>
  );
};
export default ProjectList;
